<template>

    <v-dialog transition="dialog-top-transition" max-width="600" v-model="showWithdrawal" persistent>
        <v-card class="mx-auto " max-width="600">
            <v-alert border="right" colored-border color="#8e84c0" elevation="0" max-width="600"
                class="col-12  pt-2 pb-4 mb-0">
                <v-card-title class="font-weight-medium	">{{ $t('panelWithdrawals.titleSelect') }}</v-card-title>
                <v-card-subtitle class="text-left pt-1">
                  {{ $t('panelWithdrawals.selectDesc') }}
                  </v-card-subtitle>
                <v-card-text>


                    <v-alert color="#8e84c0" dense icon="mdi-cash" class="mb-6" text type="success">
                        <p class="mb-1 text-left">  {{ $t('panelWithdrawals.amountDesc') }}:  <strong>${{amount}}</strong></p>
                    </v-alert>
                    <v-select v-model="withdrawalMethod" :items="withdrawalMethods" :label="$t('panelWithdrawals.payMethod')"    :rules="[rules.required]"
                        color="#8e84c0" item-text="text" item-value="value" item-color="#8e84c0">
                        <template v-slot:selection="{ item }">
                            <v-icon left color="#8e84c0">{{ item.icon }} </v-icon>
                            {{ item.text }}
                        </template>
                        <template v-slot:item="{ item }">
                            <v-icon left color="#8e84c0">{{ item.icon }}</v-icon>
                            {{ item.text }}
                        </template>
                    </v-select>

                    <v-form v-if="withdrawalMethod" class="mt-1">
                        <template v-if="withdrawalMethod === 'bank'">

                            <v-autocomplete :label="$t('panelWithdrawals.selectCountry')"   :placeholder="$t('panelWithdrawals.selectCountryPlaceholder')"
                                color="#8e84c0" :items="countries" item-text="name" item-value="id" v-model="bank_details.country"
                                item-color="#8e84c0"  :rules="[rules.required]" ></v-autocomplete>

                            <v-select :label="$t('panelWithdrawals.selectTypeDni')" :placeholder="$t('panelWithdrawals.selectTypeDniPlaceholder')"
                                color="#8e84c0" :items="type_documents" item-text="name" item-value="id" v-model="bank_details.type_document"
                                item-color="#8e84c0"  :rules="[rules.required]" ></v-select>

                            <v-text-field :label="$t('panelWithdrawals.documentNumber')"  :placeholder="$t('panelWithdrawals.documentNumberPlaceholder')"
                                color="#8e84c0"  v-model="bank_details.document_number"  :rules="[rules.required]"></v-text-field>

                            <v-row>
                              <v-col cols="6">
                                    <v-text-field :label="$t('panelWithdrawals.name')" :placeholder="$t('panelWithdrawals.namePlaceholder')"
                                      color="#8e84c0" v-model="bank_details.name" :rules="[rules.required]"></v-text-field>
                                  </v-col>
                                  <v-col cols="6">
                                    <v-text-field :label="$t('panelWithdrawals.lastName')" :placeholder="$t('panelWithdrawals.lastNamePlaceholder')"
                                      color="#8e84c0" v-model="bank_details.last_name" :rules="[rules.required]"></v-text-field>
                                </v-col>
                            </v-row>

                            <v-select :label="$t('panelWithdrawals.selectAccountType')" :placeholder="$t('panelWithdrawals.selectAccountTypePlaceholder')"
                                color="#8e84c0" :items="type_banks" item-text="name" item-value="id"
                                item-color="#8e84c0" v-model="bank_details.type_account" :rules="[rules.required]"></v-select>

                            <v-autocomplete :label="$t('panelWithdrawals.selectBank')"  :placeholder="$t('panelWithdrawals.selectBankPlaceholder')"
                                color="#8e84c0" :items="banks" item-text="name" item-value="id"
                                item-color="#8e84c0" v-model="bank_details.bank" :rules="[rules.required]"></v-autocomplete>

                            <v-text-field :label="$t('panelWithdrawals.accountNumber')" :placeholder="$t('panelWithdrawals.accountNumberPlaceholder')"
                                color="#8e84c0" v-model="bank_details.account_number" :rules="[rules.required, rules.numeric]"></v-text-field>
                        </template>

                        <template v-else-if="withdrawalMethod === 'crypto'">
                            <v-text-field :label="$t('panelWithdrawals.walletAdress')" :placeholder="$t('panelWithdrawals.walletAdressPlaceholder')"
                                color="#8e84c0" :hint="$t('panelWithdrawals.walletAdressHint')"
                                v-model="crypto_details.wallet_address"
                                :rules="[rules.required]"
                                ></v-text-field>

                                <v-text-field :label="$t('panelWithdrawals.redWallet')"
                                color="#8e84c0"
                                value="Tron (TRC 20)"
                                readonly
                                ></v-text-field>
                        </template>

                        <template v-else-if="withdrawalMethod === 'cosmo'">
                            <v-text-field label="ID de Cosmo" placeholder="Ingrese su ID de Cosmo"
                                color="#8e84c0"
                                v-model="cosmo_details.cosmo_id"
                                :rules="[rules.required]"
                                ></v-text-field>
                        </template>

                        <template v-else-if="withdrawalMethod === 'paypal'">
                            <v-text-field :label="$t('panelWithdrawals.paypalEmail')" :placeholder="$t('panelWithdrawals.paypalEmailPlaceholder')"
                                type="email" color="#8e84c0"
                                v-model="paypal_details.email"
                                :rules="[rules.required, rules.email]"></v-text-field>
                        </template>
                    </v-form>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                  <v-btn color="red lighten-2" @click="showWithdrawal = false" class="ml-0 rounded-md white--text mr-6 col-4">{{ $t('panelWithdrawals.buttonCancel') }}</v-btn>

                    <v-btn color="#8e84c0"  @click="confirmWithdrawal" :disabled="!isFormValid || loading" :loading="loading"
                        class="ml-0 rounded-md px-16 col-12 col-md-5  white--text">{{ $t('panelWithdrawals.confirmWithdrawal') }}</v-btn>
                </v-card-actions>
            </v-alert>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from '@/axios.js';

export default {
  name: 'WithdrawalModal',
  data() {
    return {
      withdrawalMethod: null,
      banks: [],
      type_banks: [
        { name: this.$t('panelWithdrawals.accountSavings'), id: 1 },
        { name: this.$t('panelWithdrawals.accountCurrent'), id: 2 },
      ],

      type_documents: [
        { name: this.$t('panelWithdrawals.typeDni'), id: 1 },
        { name: this.$t('panelWithdrawals.ForeignerID'), id: 2 },
        { name: this.$t('panelWithdrawals.passport'), id: 3 },
        { name: this.$t('panelWithdrawals.nit'), id: 4 },
      ],
      countries: null,
      bank_details: {
        country: null,
        type_document: null,
        document_number: null,
        type_account: null,
        bank: null,
        account_number: null,
        name: null,
        last_name: null,
      },

      crypto_details: {
        wallet_address: null,
      },

      cosmo_details: {
        cosmo_id: null,
      },

      paypal_details: {
        email: null,
      },
      loading: false,
      rules: {
        required: value => !!value || this.$t('reports.rule-required'),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('navbar.invalid-email')
        },
        alphanumeric: value => {
          const pattern = /^[a-zA-Z0-9]*$/;
          return pattern.test(value) || 'Este campo solo puede contener letras y números';
        },
        numeric: value => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || 'Este campo solo puede contener números';
        }
      }
    }
  },
  props: {
    withdraw: {
      type: Boolean,
      default: false
    },
    amount: {
      default: 0
    }
  },
  methods: {
    confirmWithdrawal() {
      this.$swal({
        title: this.$t('panelWithdrawals.alertTitle'),
        text:  this.$t('panelWithdrawals.alertText'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('panelWithdrawals.alertButtonText'),
        cancelButtonText: this.$t('panelWithdrawals.alertCancelButtonText'),
        confirmButtonColor: '#8e84c0',
        cancelButtonColor: '#d33',
      }).then((result) => {
        if (result.isConfirmed) {
          this.processWithdrawal()
        }
      })
    },


    processWithdrawal() {
      this.loading = true
      const data = {
        method: this.withdrawalMethod,
        details: this.withdrawalMethod === 'bank' ? this.bank_details : this.withdrawalMethod === 'crypto' ? this.crypto_details : this.withdrawalMethod === 'cosmo' ? this.cosmo_details : this.paypal_details
      }
      axios.post('/withdraw-funds', data)
        .then(response => {
          if (response.data.success) {
            this.$vs.notify({
              title: this.$t('panelWithdrawals.successTitle'),
              text: this.$t('panelWithdrawals.successText'),
              color: 'success',
              icon: 'check_box',
              position: 'bottom-center',
              time: 5000,
            });
          }
        })
        .catch(error => {
          this.$vs.notify({
            title: this.$t('panelWithdrawals.errorTitle'),
            text: this.$t('panelWithdrawals.errorText'),
            color: 'danger',
            icon: 'error',
            position: 'bottom-center',
            time: 5000,
          });
          console.error(error)
        })
        .finally(() => {
          this.loading = false
          this.showWithdrawal = false
          this.$root.$emit('EarningsUpdated');
        })
    },


    getBanks() {
      axios.get('/get-banks')
        .then(response => {
          if (response.data) {
            this.banks = response.data
          }
        })
        .catch(error => {
          console.error(error)
        })
    },

    async getCountries() {
      try {
        const response = await axios.get('/get-phone-codes');

        this.countries = response.data.map(country => ({
          id: country.id,
          name: country.name,
        }));
      } catch (error) {
        console.error(error);
      }
    },
  },


  computed: {
    isFormValid() {
      if (this.withdrawalMethod === 'bank') {
        return (
          this.rules.required(this.bank_details.country) === true &&
          this.rules.required(this.bank_details.type_document) === true &&
          this.rules.required(this.bank_details.document_number) === true &&
          this.rules.required(this.bank_details.type_account) === true &&
          this.rules.required(this.bank_details.bank) === true &&
          this.rules.required(this.bank_details.account_number) === true &&
          this.rules.numeric(this.bank_details.account_number) === true
        );
      } else if (this.withdrawalMethod === 'crypto') {
        return this.rules.required(this.crypto_details.wallet_address) === true;
      } else if (this.withdrawalMethod === 'cosmo') {
        return this.rules.required(this.cosmo_details.cosmo_id) === true;
      } else if (this.withdrawalMethod === 'paypal') {
        return (
          this.rules.required(this.paypal_details.email) === true &&
          this.rules.email(this.paypal_details.email) === true
        );
      }
      return false;
    },
    showWithdrawal: {
      get() {
        return this.withdraw;
      },
      set(value) {
        this.$emit('update:withdraw', value);
      }
    },

    withdrawalMethods() {
    return [
      { text: this.$t('panelWithdrawals.payBank'), value: 'bank', icon: 'mdi-bank' },
      { text: 'Crypto (USDT)', value: 'crypto', icon: 'mdi-bitcoin' },
      { text: 'Cosmo', value: 'cosmo', icon: 'mdi-earth' },
      { text: this.$t('panelWithdrawals.paypalSelectText'), value: 'paypal', icon: 'mdi-wallet' },
    ];
  }

  },
  watch: {
    showWithdrawal(newVal) {
      if (!newVal) {
        this.withdrawalMethod = null;
        this.bank_details = {
          country: null,
          type_document: null,
          document_number: null,
          type_account: null,
          bank: null,
          account_number: null,
        };
        this.crypto_details = {
          wallet_address: null,
        };
        this.cosmo_details = {
          cosmo_id: null,
        };
        this.paypal_details = {
          email: null,
        };
      }
    }
  },

  mounted() {
    this.getBanks()
    this.getCountries()
  }
}
</script>
