<template>
    <v-container class="my-10 my-md-16 px-3">
        <v-row class="d-block d-sm-flex flex-row heading">
            <v-col>
                <h1 class="text-left">{{ $t("panelHome.title") }}</h1>
                <p class="text-left">
                    {{ $t("panelHome.description") }}
                </p>
            </v-col>

            <v-row no-gutters class="d-flex  flex-sm-row justify-end align-sm-center align-start px-3 ">
                <!-- <v-tooltip top>
                    <template v-slot:activator="{ on , attrs}">
                        <v-btn v-bind="attrs" v-on="on" color="success"
                            class=" mt-2 rounded-pill py-5 mb-9 mb-sm-0 mx-2" small
                            :disabled="earnings <= 25"
                            >
                            {{ $t('panelHome.withdraw') }}
                        </v-btn>
                    </template>
<span>{{ $t('panelHome.tooltip-withdraw') }}</span>
</v-tooltip> -->

                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="d-inline-block"> <!-- Envolver en un div -->
                            <v-btn color="success" class="mt-2 rounded-pill py-5 mb-4 mb-sm-0 mx-2" small
                                :disabled="earnings < 25" @click="withdraw = !withdraw">
                                {{ $t('panelHome.withdraw') }}
                            </v-btn>
                        </div>
                    </template>
                    <span v-if="earnings >= 25">
                        {{ $t('panelHome.tooltip-withdraw') }}
                    </span>
                    <span v-else>
                        {{ $t('panelHome.tooltip-disabled') }} <!-- Tooltip alternativo cuando está deshabilitado -->
                    </span>
                </v-tooltip>

                <v-btn v-if="verificationCode" outlined color="white"
                    class="button-referrals mt-2 mx-2 rounded-pill py-5 mb-4 mb-sm-0" small to="/panel/referrals">
                    <v-icon>mdi-braille</v-icon>{{ $t('panelHome.referrals') }}
                </v-btn>

                <v-btn outlined color="white" @click="dialogMoreMoney = !dialogMoreMoney"
                    class="button-more-money mt-2 mx-2 rounded-pill py-5 mb-4 mb-sm-0" small>
                    <v-icon>mdi-cash-plus</v-icon>{{ $t('moreMoney.button') }}
                </v-btn>


                <v-btn outlined color="white" class="button-create mt-2 ml-1 rounded-pill py-5 mb-4 mb-sm-0" small
                    @click="createSite = !createSite">
                    <v-icon>mdi-auto-fix</v-icon>{{ $t('panelHome.createHarmony') }}
                </v-btn>
            </v-row>
        </v-row>

        <v-row align="center" justify="center" class="w-full mt-10">
            <v-col class="harmony-section  col-md-12 col-12 mt-7 mt-md-2 pt-2 pb-10 px-0 w-full">
                <v-row no-gutters align="center" class="justify-center  col-12 pa-3">
                    <span class="pa-3  desc-loading">
                        {{ $t('panelHome.foundHarmonys',) }}
                    </span>
                    <v-icon left size="20px" color="#978ace">
                        mdi-emoticon-excited
                    </v-icon>
                </v-row>

                <v-row no-gutters align="start" justify="start" class="d-flex h-100">
                    <v-col
                        class="harmony-empty rounded-xl pt-8 pb-7 col-md-3 col-12 mx-0 mt-4 mt-md-0 mx-md-3 px-4 px-md-0 text-center"
                        v-if="!sites && !sitesLoading">
                        <img src="../assets/illustrations/empty.svg" class="empty">
                        <p class="mb-0 mt-0 empty-card-desc">{{ $t('panelHome.noContent') }}</p>
                    </v-col>

                    <v-row v-if="sitesLoading" class="col-12 mx-0 my-md-3 px-3">

                        <v-col
                            class="col-12 col-sm-6 col-md-4 harmony-loader rounded-xl mb-8 mt-md-13 py-8 text-center px-3">
                            <v-skeleton-loader type="image" class="harmony-loader"></v-skeleton-loader>
                        </v-col>

                        <v-col
                            class="col-12 col-sm-6 col-md-4 harmony-loader rounded-xl mb-8 mt-md-13 py-8 text-center px-3">
                            <v-skeleton-loader type="image" class="harmony-loader"></v-skeleton-loader>
                        </v-col>

                        <v-col
                            class="col-12 col-sm-6 col-md-4 harmony-loader rounded-xl mb-8 mt-md-13 py-8 text-center px-3">
                            <v-skeleton-loader type="image" class="harmony-loader"></v-skeleton-loader>
                        </v-col>

                    </v-row>

                </v-row>

                <v-row no-gutters align="center" justify="start" class="">

                    <template v-if="!sitesLoading">

                        <v-col v-for="site in sites" :key="site.id" class="col-md-4 col-sm-6 col-12 mx-0 my-md-3 px-3">

                            <v-col
                                class="col-12 harmony-site mb-8 mt-md-0 mx-auto py-8 d-flex flex-column justify-sm-space-between justify-space-around">
                                <div>
                                    <v-badge overlap :color="getStatusColor(site.status_id)" offset-x="25" offset-y="25"
                                        :icon="getIcon(site.status_id)">
                                        <div class="border-img"
                                            :style="{ backgroundColor: getStatusColor(site.status_id) }">

                                            <v-img
                                                :src="site.logo_path ? site.logo_path : require('../assets/illustrations/no-photo.jpg')"
                                                width="100" height="100" class="rounded-pill cover mx-auto"></v-img>
                                        </div>
                                    </v-badge>

                                    <v-col class="text-center">
                                        <p class="mb-0 mt-0 empty-card-desc wrap text-capitalize"
                                            style="color: rgb(88, 88, 88);">{{
                                            site.name }}</p>
                                    </v-col>

                                    <div>
                                        <p class="mb-2 mt-0 empty-card-desc text-subtitle-2 wrap "> {{
                                            descriptionFilter(site.description)}} </p>
                                    </div>

                                </div>
                                <div>
                                    <v-alert :color="getStatusColor(site.status_id)" dense
                                        class="py-3 mb-0 font-weight-bold" shaped text>
                                        {{ language == 'es' ? site.status.name : site.status.name_en }}
                                    </v-alert>
                                    <div class="text-center mt-4 mb-0">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn v-bind="attrs" v-on="on" elevation="0" color="green"
                                                    class="m-0 mt-1 mr-0 py-2 px-0 btn-action rounded-pill" small
                                                    @click="goHarmony('/' + site.slug)
                                                        ">
                                                    <v-icon center color="white" size="20px">
                                                        mdi-web
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('panelHome.statusView') }}</span>
                                        </v-tooltip>

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn elevation="0" color="#2098d1"
                                                    class="m-0 mt-1 mx-4 py-2 px-0 rounded-pill btn-action" small
                                                    v-clipboard:copy="'https://harmony.fan/' + site.slug"
                                                    v-clipboard:success="onCopy" v-bind="attrs" v-on="on">
                                                    <v-icon center color="white" size="20px">
                                                        mdi-content-copy
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('panelHome.statusCopyLink') }}</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn elevation="0" color="#8e84c0"
                                                    class="mt-1 mr-0 py-2 px-0 btn-action rounded-pill" small
                                                    @click="showQr(site.id, site.slug)" v-bind="attrs" v-on="on">
                                                    <v-icon center color="white" size="20px">
                                                        mdi-qrcode-scan
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{$t('panelHome.qrcode-title')}}</span>
                                        </v-tooltip>

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn elevation="0" color="#ffbc5a"
                                                    class="ml-4 m-0 mt-1 mr-0 py-2 px-0 btn-action rounded-pill" small
                                                    :href="'/' + site.slug + '/editor'" v-bind="attrs" v-on="on">
                                                    <v-icon center color="white" size="20px">
                                                        mdi-square-edit-outline
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('panelHome.statusEditProfile') }}</span>
                                        </v-tooltip>

                                        <!--
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn elevation="0" :disabled="deleteLoading" :loading="deleteLoading"
                                                    color="#eb7c7c" class="m-0 mt-1 py-2 px-0 btn-action rounded-pill"
                                                    small @click="confirmDelete(site.id)" v-bind="attrs" v-on="on">
                                                    <v-icon center color="white" size="15px">
                                                        mdi-trash-can-outline
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <span>{{ $t('panelHome.statusDelete') }}</span>
                                        </v-tooltip> -->
                                    </div>
                                </div>
                            </v-col>

                        </v-col>
                    </template>

                    <v-dialog v-model="dialog" max-width="375px" class="qrCode">
                        <v-card class="elevation-0 pa-0" id="qrCard">
                            <div>
                                <!-- <v-card-title>{{$t('panelHome.qrcode-title')}}</v-card-title> -->
                                <v-card-text class="d-flex justify-center align-center flex-column pa-0">
                                    <!-- <v-btn text color="#8e84c0" :href="'/' + slug">harmony.fan/{{ slug }}</v-btn> -->
                                    <img :src="qrUrl" alt="QR Code" class="rounded-xl">
                                </v-card-text>
                            </div>
                            <v-btn text dense color="#8e84c0" class="download-qr"
                                @click="downloadQr()">{{$t('panelHome.download')}}</v-btn>

                        </v-card>
                    </v-dialog>
                </v-row>

            </v-col>
        </v-row>

        <WithdrawModal :withdraw.sync="withdraw" :amount="earnings" @earningsUpdated="getEarnings">
        </WithdrawModal>

        <v-dialog transition="dialog-top-transition" max-width="600" v-model="createSite">
            <v-card>
                <v-card-text class="py-0 px-0">
                    <v-form ref="formSite" lazy-validation>
                        <v-alert border="right" colored-border color="#8e84c0" elevation="0"
                            class="col-12 px-10 pt-9 pb-8 mb-0">
                            <div class="text-left">

                                <v-alert v-if="harmony.length <= 2" color="#8e84c0" dense icon="mdi-search-web"
                                    class="mb-6" shaped text type="success">
                                    <p class="mb-1">{{ $t('panelHome.linkLabel') }}</p>
                                    <strong class="break">{{ harmonyUrl }}</strong>
                                </v-alert>

                                <v-alert v-if="harmonyExist && harmony.length > 2 && !isFormValid && harmonyExist"
                                    color="red" dense icon="mdi-blur-off" class="mb-6" shaped text type="success">
                                    <p class="mb-1">{{ $t('panelHome.nameExists') }}</p>
                                    <strong class="break">{{ harmonyUrl }}</strong>
                                </v-alert>

                                <v-alert v-else-if="!harmonyExist && harmony.length > 2 && isFormValid" color="green"
                                    dense icon="mdi-blur" class="mb-6" shaped text type="success">
                                    <p class="mb-1">{{ $t('panelHome.nameAvailable') }}</p>
                                    <strong class="break">{{ harmonyUrl }}</strong>
                                </v-alert>

                                <v-text-field v-model="harmony" type="text" :label="$t('panelHome.nameLabel')"
                                    color="#8e84c0" filled :rules="harmonyRules"
                                    @input="updateHarmonyUrl"></v-text-field>

                                <v-textarea v-model="harmonyDesc" :label="$t('panelHome.descriptionLabel')" type="text"
                                    color="#8e84c0" class="mb-0" counter rows="2"
                                    :rules="[rules.maxDesc , rules.containUrl]"></v-textarea>
                            </div>
                            <div class="text-right">
                                <v-btn :disabled="!isFormValid || harmonyLoading || !isDescriptionValid" outlined
                                    color="#8e84c0" :loading="harmonyLoading"
                                    class="ml-0 mt-2 rounded-md px-16 col-12 col-md-5" @click="createHarmony">
                                    <v-icon left color="#8e84c0">
                                        mdi-shape-plus
                                    </v-icon>
                                    {{ $t('panelHome.createHarmony') }}
                                </v-btn>
                            </div>
                        </v-alert>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogMoreMoney"  transition="dialog-top-transition" max-width="600" class="dialog">
            <v-card class="mx-auto" max-width="600">
                <v-alert border="right" colored-border color="#8e84c0" elevation="0" max-width="600"
                    class="col-12 pt-2 pb-4 mb-0">
                    <v-card-title class="font-weight-medium justify-center ">{{ $t('moreMoney.title') }}</v-card-title>
                    <v-card-text class="pa-0">
                        <v-list-item v-for="item in listMoney" :key="item.title">
                            <v-list-item-icon class="d-none d-sm-block justify-center">
                                <v-icon color="#8e84c0">{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-center text-wrap text-sm-left">
                                    <v-icon color="#8e84c0" class="d-block d-sm-none text-center mb-sm-0 mb-2">{{ item.icon
                                        }}</v-icon>
                                    {{ item.title }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="text-center text-wrap text-sm-left mt-sm-0 mt-0">
                                    {{ item.description }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-text>
                </v-alert>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import axios from '@/axios.js';
import WithdrawModal from '@/components/partials/WithdrawModal.vue';

export default {
    name: 'Home',

    data() {
        return {
            sites: null,
            createSite: false,
            harmony: '',
            harmonyDesc: '',
            harmonyUrl: 'harmony.fan/',
            language: this.$i18n.locale,
            formattedHarmony: '',
            harmonyExist: false,
            harmonyLoading: false,
            deleteLoading: false,
            sitesLoading: false,
            earnings: 0,
            withdraw: false,
            rules: {
                maxDesc: v => v.length <= 200 || this.$t('panelHome.data-maxDesc200'),
                containUrl: value => {
                    if (!value) return true;
                    const urlRegex = RegExp(/(https?:\/\/[^\s]+|www\.[^\s]+|\b(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?\b|(?<![a-zA-Z])(?:\+?\d{1,3})?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}\b(?!\d)(?=\s|$)|(?<!\d)(?=.*[a-zA-Z])\S*\d{5,}\S*)/g);
                    return !urlRegex.test(value) || this.$t('harmony.contains-url');
                },
            },
            harmonyRules: [
                v => !!v || this.$t('panelHome.data-required'),
                v => v.length >= 3 || this.$t('panelHome.data-minChar'),
                v => v.length <= 20 || this.$t('panelHome.data-maxChar'),
                v => /^(?!.*[.]{2})(?!.*[_]{2})(?!.*[ ]{2})[a-zA-Z0-9._ ]*[a-zA-Z0-9]$/.test(v) || this.$t('panelHome.data-validChars'),
                () => !this.harmonyExist || this.$t('panelHome.data-harmonyExist'),
            ],
            verificationCode: [],
            dialog: false,
            qrUrl: null,
            qrBlob: null,
            slug: '',
            dialogMoreMoney: false,
        };
    },
    components: {
        WithdrawModal
    },

    mounted() {
        this.$root.$on('EarningsUpdated', this.getEarnings);

        // Agregar el script de Tawk.to
        var Tawk_API = Tawk_API || {}
        //Tawk_LoadStart = new Date();
        var s1 = document.createElement("script");
        s1.async = true;
        s1.src = 'https://embed.tawk.to/66eb28ff4cbc4814f7da53e3/1i839g9gv';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        var s0 = document.getElementsByTagName("script")[0];
        s0.parentNode.insertBefore(s1, s0);
    },

    computed: {
        isFormValid() {
            return this.harmonyRules.every(rule => rule(this.harmony) === true)
        },
        isDescriptionValid() {
            if (this.rules.maxDesc(this.harmonyDesc) === true &&
                this.rules.containUrl(this.harmonyDesc) === true) {
                return true
            }
            return false
        },
        listMoney() {
            return [
                {
                    title: this.$t('moreMoney.list.socialNetworks.title'),
                    description: this.$t('moreMoney.list.socialNetworks.description'),
                    icon: 'mdi-account'
                },
                {
                    title: this.$t('moreMoney.list.periodicUpdate.title'),
                    description: this.$t('moreMoney.list.periodicUpdate.description'),
                    icon: 'mdi-calendar-account'
                },
                {
                    title: this.$t('moreMoney.list.referralProgram.title'),
                    description: this.$t('moreMoney.list.referralProgram.description'),
                    icon: 'mdi-handshake-outline'
                },
                {
                    title: this.$t('moreMoney.list.broadcastSchedule.title'),
                    description: this.$t('moreMoney.list.broadcastSchedule.description'),
                    icon: 'mdi-calendar'
                },
                {
                    title: this.$t('moreMoney.list.stories.title'),
                    description: this.$t('moreMoney.list.stories.description'),
                    icon: 'mdi-share-variant'
                },
                {
                    title: this.$t('moreMoney.list.qrCode.title'),
                    description: this.$t('moreMoney.list.qrCode.description'),
                    icon: 'mdi-qrcode'
                },
                {
                    title: this.$t('moreMoney.list.referralLink.title'),
                    description: this.$t('moreMoney.list.referralLink.description'),
                    icon: 'mdi-link'
                }
            ]
        }
    },

    created() {
        this.getSites();
        this.getEarnings();
        this.verificationCodeReferral();
    },

    methods: {
        confirmDelete(siteId) {
            // Muestra la alerta de confirmación
            this.$swal.fire({
                title: this.$t('panelHome.confirmDelete-title'),
                text: this.$t('panelHome.confirmDelete-text'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: 'rgb(112 201 109)',
                cancelButtonColor: '#eb7c7c',
                confirmButtonText: this.$t('panelHome.confirmDelete-ButtonText'),
                cancelButtonText: this.$t('panelHome.confirmDelete-cancelButtonText'),
            }).then((result) => {
                if (result.isConfirmed) {
                    // Llama a la función para eliminar el harmony si el usuario confirma
                    this.deleteHarmony(siteId);
                }
            });
        },

        async deleteHarmony(siteId) {
            this.deleteLoading = true;

            try {
                await axios.post('/set-site-status', { site_id: siteId, status_id: 4 });

                this.$vs.notify({
                    title: this.$t('panelHome.delete-title'),
                    text: this.$t('panelHome.delete-text'),
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                })

                this.deleteLoading = false;
                this.getSites()


            } catch (error) {
                this.deleteLoading = false;
                console.log(error.response)
            }
        },

        onCopy() {
            this.$vs.notify({
                title: this.$t('panelHome.onCopy-title'),
                text: this.$t('panelHome.onCopy-text'),
                color: 'rgb(95 189 233)',
                icon: 'content_copy',
                position: 'bottom-center',
                time: 5000,
            })
        },

        goHarmony(value) {
            window.open(value, '_blank');
        },

        async getSites() {
            this.sitesLoading = true
            try {
                const response = await axios.get('/get-my-sites');
                this.sites = response.data.sites && response.data.sites.length > 0 ? response.data.sites : null;
                this.sitesLoading = false
            } catch (error) {
                this.sitesLoading = false
                console.error(error);
            }
        },

        async updateHarmonyUrl() {
            this.formattedHarmony = this.harmony.toLowerCase().replace(/ /g, '');
            this.harmonyUrl = 'harmony.fan/' + this.formattedHarmony;

            if (this.harmony) {
                await this.checkHarmonyExist(this.formattedHarmony);
            }
        },

        async checkHarmonyExist(slug) {
            try {
                await axios.post('/check-site', { slug });
                this.harmonyExist = false; // El sitio no existe
            } catch (error) {
                if (error.response && error.response.status === 302) {
                    this.harmonyExist = true; // El sitio ya existe
                } else {
                    console.error(error);
                }
            }
            this.$nextTick(() => {
                this.$refs.formSite.validate();
            });
        },

        async createHarmony() {
            if (!this.isFormValid || this.harmonyExist) return;

            this.harmonyLoading = true;

            try {
                await axios.post('/create-site', { name: this.harmony, slug: this.harmony, description: this.harmonyDesc });
                this.$vs.notify({
                    title: this.$t('panelHome.createHarmony-title'),
                    text: this.$t('panelHome.createHarmony-text'),
                    color: 'success',
                    icon: 'check_box',
                    position: 'bottom-center',
                    time: 5000,
                })
                //this.$router.push(`/${this.formattedHarmony}/editor`);
                window.location.href = this.$router.resolve({ path: `/${this.formattedHarmony}/editor` }).href;

            } catch (error) {
                this.createSite = false
                this.harmonyLoading = false;
                console.log(error.response)
            }
        },

        async verificationCodeReferral() {
            const response = await axios.get('/verification-code-referral');
            this.verificationCode = response.data.verification;
        },

        getStatusColor(value) {
            switch (true) {
                case value == 1:
                    return '#ffbc5a'
                case value == 2:
                    return 'rgb(181 181 181)'
                case value == 3:
                    return '#88e790'
            }
        },

        getIcon(value) {
            switch (true) {
                case value == 1:
                    return 'mdi-lead-pencil'
                case value == 2:
                    return 'mdi-eye-off'
                case value == 3:
                    return 'mdi-eye-outline'
            }
        },

        async getEarnings() {
            try {
                const response = await axios.get("get-earnings");
                if (response) {
                    this.earnings = response.data.total_value
                }
            } catch (error) {
                console.error("Error:", error);
            }
        },

        showQr(site_id, slug) {
            this.slug = slug;
            this.qrBlob = null
            axios
                .post('/generate-qr', { site_id, slug })
                .then(response => {
                    this.qrUrl = response.data.qr_url;
                    if (response.data.file) {
                        const binary = atob(response.data.file);
                        const array = [];
                        for (let i = 0; i < binary.length; i++) {
                            array.push(binary.charCodeAt(i));
                        }
                        this.qrBlob = new Blob([new Uint8Array(array)], { type: 'image/png' });
                    }

                    this.dialog = true;
                })
                .catch(error => {
                    console.error('Error generando el código QR:', error);
                });
        },
        downloadQr() {
            if (this.qrBlob) {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(this.qrBlob);
                link.download = 'qrcode.png';
                link.click();
            }
        },
        descriptionFilter(text){
            if(text){
                const cleanedText = text.replace(/(https?:\/\/[^\s]+|www\.[^\s]+|\b(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(?:\/[^\s]*)?\b|(?<![a-zA-Z])(?:\+?\d{1,3})?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}\b(?!\d)(?=\s|$)|(?<!\d)(?=.*[a-zA-Z])\S*\d{5,}\S*)/g, "");
                return cleanedText
            }
            return ''
        }
    },

    beforeDestroy() {
        this.$root.$off('EarningsUpdated', this.getEarnings);
    }

};
</script>

<style scoped>

.download-qr {
    background-color: #8e84c080;
    color: rgba(255, 255, 255, 0.623) !important;
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: 0.25s;
}
#qrCard:hover .download-qr{
    color: rgb(255, 255, 255) !important;
    background-color: #8e84c0;
}

.v-dialog {
    box-shadow: none;
}

.dialog .v-dialog ::-webkit-scrollbar {
    display: none !important;

}

.btn-action {
    min-width: fit-content !important;
    width: 40px !important;
    height: 40px !important;
    padding: 0 !important;
}

.wrap {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}

.button-create {
    width: fit-content;
    border: none;
    border-radius: 50%;
    background: #7343D9;
    -webkit-box-shadow: 1px 2px 3px 0px rgb(172, 172, 172);
    -moz-box-shadow: 1px 2px 3px 0px rgba(172, 172, 172);
    box-shadow: 1px 2px 3px 0px rgba(172, 172, 172);
}

.button-referrals {
    width: fit-content;
    border: none;
    border-radius: 50%;
    background: #df5cc2;
    -webkit-box-shadow: 1px 2px 3px 0px rgb(172, 172, 172);
    -moz-box-shadow: 1px 2px 3px 0px rgba(172, 172, 172);
    box-shadow: 1px 2px 3px 0px rgba(172, 172, 172);
}

.button-more-money {
    width: fit-content;
    padding: 15px 30px;
    border: none;
    border-radius: 50%;
    background: linear-gradient(145deg, #2ecc71, #27ae60);
    box-shadow: 0px 4px 15px rgba(46, 204, 113, 0.3);
    transition: all 0.3s ease;
    cursor: pointer;
}

.button-more-money:hover {
    background: linear-gradient(145deg, #27ae60, #2ecc71);
    box-shadow: 0px 6px 20px rgba(46, 204, 113, 0.4);
}

.disabled-button {
    background: #888888 !important;
    color: #000 !important;
}

.button-pay {
    background: #52c457;
    border: none;
    border-radius: 50%;
    -webkit-box-shadow: 1px 2px 3px 0px rgb(172, 172, 172);
    -moz-box-shadow: 1px 2px 3px 0px rgba(172, 172, 172);
    box-shadow: 1px 2px 3px 0px rgba(172, 172, 172);
}

.heading,
.harmony-section {

    border-radius: 20px;
}

.harmony-section {
    background: #978ace2e;
    border-radius: 20px;
}

.heading h1 {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.heading p,
.desc-loading {
    font-size: 0.8rem;
    color: #978ace;
    font-weight: 600;
}

.break {
    word-break: break-word;
    overflow-wrap: break-word;
}

.border-img {
    margin: auto;
    width: fit-content;
    height: fit-content;
    border-radius: 50%;
    border: 8px solid rgba(0, 0, 0, 0) !important;
    -webkit-box-shadow: 3px 2px 12px 0px rgb(201, 201, 201);
    -moz-box-shadow: 3px 2px 12px 0px rgba(201, 201, 201);
    box-shadow: 3px 2px 12px 0px rgba(201, 201, 201);
}


.published .v-icon {
    margin-left: 0px !important;
}


.harmony-site {
    height: 27rem;
    background: white;
    border-radius: 30px;
    -webkit-box-shadow: 3px 2px 12px 0px rgb(201, 201, 201);
    -moz-box-shadow: 3px 2px 12px 0px rgba(201, 201, 201);
    box-shadow: 3px 2px 12px 0px rgba(201, 201, 201);
    transition: 0.2s
}

.harmony-site:hover .empty-card-desc {

    text-shadow: 3px px 3px rgba(0, 0, 0, 0.5);
}

.empty {
    margin-top: -19px;
}

.empty-card-desc {
    font-size: 1.5rem;
    font-weight: 700;
    color: rgb(153, 153, 153);
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
}

.harmony-create {
    max-width: 10rem;
    cursor: pointer;
    background-color: #aaa5c1;
}

.harmony-empty {
    cursor: help;
    background-color: #eae9f3;
    opacity: 60% !important;
}

.empty {
    width: 148px;
}

@media (max-width: 959px) {
    .harmony-create {
        max-width: 100%;
    }

    .desc-loading {
        font-size: 11px;
    }

    .harmony-site {
        max-width: 98%;
    }
}

#qrCard{
    background: #e6e6ff;
    position: relative;
}

#qrCard img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

</style>

<style>
.v-dialog{
    border-radius: 25px;
}
.harmony-loader .v-skeleton-loader__image {
    height: 27rem;
    border-radius: 30px;
}
</style>